import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/TejasMane.png";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              A Senior Laravel Developer with over 3 years of experience, specializing in building robust web applications using <i><b className="purple">Laravel</b></i>, <i><b className="purple">Vue.js</b></i>, and <i><b className="purple">React</b></i>. Expertise includes payment gateway integration, front-end development, and back-end operations, with a focus on optimizing databases for reliable performance.
              <br />
              <br />
              Passionate about creating innovative web technologies and products, with a keen interest in <b className="purple">e-commerce</b> and <b className="purple">blockchain</b> solutions.
              <br />
              <br />
              Currently leading a team at <b className="purple">Orion Solution</b>, managing various stages of project development, including the integration of payment systems like Afterpay, Braintree, and PayPal.
              <br />
              <br />
              Enjoys exploring new technologies, participating in coding clubs, and staying updated with tech blogs during free time.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/tejas-mane-b17284278/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Home2;
